<script lang="ts" setup>
import { type FieldContext, useField } from 'vee-validate'
import type { InputProps } from './FInput.vue'

type Props = InputProps & {
  mode?: string
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'eager',
  isLoading: false,
})

const emit = defineEmits(['update:modelValue'])

const handleUpdateModelValue = (newValue: any) => {
  emit('update:modelValue', newValue)
}

const { modes } = useVeeModes()

// const { value, errorMessage } = useField(props.name!)

const { meta, value, errorMessage, handleChange, handleBlur } = useField(
  toRef(props, 'name'),
  value => !!value,
  {
    validateOnValueUpdate: false,
  },
)

// generates the listeners
const handlers = computed(() => {
  const on: Record<string, any> = {
    blur: handleBlur,
    // default input event to sync the value
    // the `false` here prevents validation
    input: [(e: any) => handleChange(e, false)],
  }

  // // Get list of validation events based on the current mode
  const triggers = modes[props.mode]({
    errorMessage: errorMessage as Ref<string | undefined>,
    meta,
  } as FieldContext)

  // // add them to the "on" handlers object
  triggers.forEach(t => {
    if (Array.isArray(on[t]))
      on[t].push(handleChange)
    else
      on[t] = handleChange
  })

  return on
})
</script>

<template>
  <FInput v-bind="props" v-model="value" :error="errorMessage" :is-loading="props.isLoading" v-on="handlers" @update:model-value="handleUpdateModelValue">
    <template v-if="!!$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="!!$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
  </FInput>
</template>
